import styled from 'styled-components'
import CosLink from '../../Link'

const LightLink = styled(CosLink)`
  color: black;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.3em;
  text-decoration: none;
  &:hover {
    color: #990000;
  }
  &:active {
    font-weight: bold;
  }
  @media (max-width: 810px) {
    line-height: 1.5em;
  }
  &:after {
    content: ' |';
    color: hsla(0, 0%, 0%, 0.2);
  }
  &:before {
    content: ' ';
  }
  &:last-child:after {
    content: '';
  }
`

export default LightLink
