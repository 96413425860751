import React from 'react'
import styled from 'styled-components'
import TypistCycle from './TypistCycle'
import Hero from '../../images/gorgaslibrary01_1920.jpg'
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Link } from '@reach/router';
// @import url('https://fonts.googleapis.com/css2? family= Roboto:wght@300 & display=swap');

export default () => (
  <div>
    <Content>
      <TextBackground>
        <TypistCycle 
          content={['Good Data', 'Good Decisions']}
          numberOfCycles={-1} // loop indefinitely
          segmentDelay={2} // stop for 0.8s at end line
          cursor={{
            element: '_',
            hideWhenDoneDelay: 3000,
          }} 
        />
      </TextBackground>
    </Content>
    <div id="icon-menu" className="container-lg d-none d-md-block top-border">
      <div className="row p-3 justify-content-center">
      <Link to="/new/reports/?Report%20Type[0][0]=Common%20Data%20Set" className="side-border text-center col-md-3">
          <button type="button" className="icon-menu-btn border border-0">
            <div className="icon-menu">
              <span className="icon-menu-icon"><i class="bi bi-bar-chart-line-fill"></i></span><br />
              <span className="icon-menu-text">Common Data Set</span>
            </div>
          </button>
        </Link>
        <Link to="/new/interactiveFB/" className="side-border text-center col-md-2">
          <button type="button" className="icon-menu-btn border border-0">
            <div className="icon-menu">
              <span className="icon-menu-icon"><i class="bi bi-book-half"></i></span><br />
              <span className="icon-menu-text">Factbook</span>
            </div>
          </button>
        </Link>
        <Link to="/new/requests/" className="side-border text-center col-md-2">
          <button type="button" className="icon-menu-btn border border-0">
            <div className="icon-menu">
              <span className="icon-menu-icon"><i class="bi bi-inboxes-fill"></i></span><br />
              <span className="icon-menu-text">Information Requests</span>
            </div>
          </button>
        </Link>
        <Link to="/new/internalReports/" className="side-border text-center col-md-2">
          <button type="button" className="icon-menu-btn border border-0">
            <div className="icon-menu">
              <span className="icon-menu-icon"><i class="bi bi-file-earmark-bar-graph"></i></span><br />
              <span className="icon-menu-text">Internal Reports<br />(Login Required)</span>
            </div>
          </button>
        </Link>
        <Link to="/new/soi/" className="side-border text-center col-md-3">
          <button type="button" className="icon-menu-btn border border-0">
            <div className="icon-menu">
              <span className="icon-menu-icon"><i class="bi bi-chat-right-text-fill"></i></span><br />
              <span className="icon-menu-text">Student Opinions of Instruction</span>
            </div>
          </button>
        </Link>
      </div>
    </div>
  </div>
)

const Content = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-flow: column;
  height: 500px;
  -webkit-overflow-scrolling: touch;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: url(${Hero}) no-repeat;
  background-size: cover;
  background-position: center center;

  h1 {
    font-size: 3vw;
  }
  @media (max-width: 768px) {
    height: 300px;
  }
`

const TextBackground = styled.div`
  margin-top: -5em;
  color: RGBA(0, 0, 0, 0.8);
  border-radius: 5px;
  font-weight: 700;
  padding-left: 5px;
  padding-right: 10px;
  line-height: 1.5em;
  font-size: 3.5em;
  text-align: center;
  background: RGBA(245, 243, 247, 0.6);
  .Typist .Cursor {
    display: inline-block;
  }
  .Typist .Cursor--blinking {
    opacity: 1;
    animation: blink 1s linear infinite;
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    line-height: 3.5rem;
    font-size: 2.5rem;
    margin-top: -3.5em;
  }
`
