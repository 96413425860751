import React, { useLayoutEffect } from 'react'

const CookieWrap = () => {
    useLayoutEffect(() => {
        const getCookie = (name) => {
            const value = ' ' + document.cookie;
            const parts = value.split(' ' + name + '=');
            return parts.length < 2 ? undefined : parts.pop().split(';').shift();
        };

        const setCookie = function (name, value, expiryDays, path, domain, sameSite, secure) {
            const expDate = new Date();
            expDate.setHours(
                expDate.getHours() +
                (typeof expiryDays !== 'number' ? 90 : expiryDays) * 24
            );
            const cookie =
                name +
                "=" +
                value +
                ";expires=" +
                expDate.toUTCString() +
                ";path=" +
                (path || "/") +
                (domain ? ";domain=" + domain : "") +
                (sameSite ? ";SameSite=" + sameSite : "") +
                (secure ? ";secure" : "");
            console.log("cookie", cookie);
            document.cookie = cookie;
        };

        const cookiesBanner = document.getElementById('ua_cookie-banner__container');
        const cookiesBannerBtn = document.querySelector('.ua_cookie-banner__container button');
        const cookieName = 'UA-CookiesConsent';
        const hasCookie = getCookie(cookieName);
        const hostName = window.location.hostname;

        if (!hasCookie) {
            cookiesBanner.removeAttribute('hidden');
        }

        cookiesBannerBtn.addEventListener('click', () => {
            setCookie(cookieName, 'closed', 90, undefined, hostName.includes('oira.ua.edu') ? 'oira.ua.edu' : undefined, 'None', true);
            cookiesBanner.remove();
        });
    }, [])

    return null;
}
export default CookieWrap