import React from 'react'
import PropTypes from 'prop-types'
import Main from './Main'
import LightNav from './Navigation'
import LightLink from './Navigation/LightLink'
import LogoFooter from './Footer'
import GDGD from '../../images/gdgd.svg'
import './Layout.css'
// import './uatemplate.css'
import './bootstrap-classes.css'
import HelpfulLinks from './HelpfulLinks'
import { Link } from '@reach/router'
import 'bootstrap-icons/font/bootstrap-icons.css';

const Layout = props => (
  <div className="react-root">
    <LightNav maxWidth={props.maxWidth}>
      <LightLink to="/contact/"><i class="bi bi-envelope-at"></i> {'Contact Us'}</LightLink>
      {/* <LightLink to="/mission/">{'Mission'}</LightLink> */}
      {/* <LightLink to="/reports/?Report%20Type[0][0]=Common%20Data%20Set">{'Common Data Set (CDS)'}</LightLink> */}
      {/* <LightLink to="/interactiveFB/">{'Factbook'}</LightLink> */}
      {/* <LightLink to="/requests/">{'Information Requests'}</LightLink> */}
      {/* <LightLink to="/staff/">{'Staff'}</LightLink> */}
      {/* <LightLink to="/resources/">{'Resources'}</LightLink> */}
    </LightNav>
    <div>
      {props.upper ? props.upper : null}
      <Main maxWidth={props.maxWidth}>
        <article>
            {props.children}
        </article>
      </Main>
    </div>

    <LogoFooter maxWidth={props.maxWidth} image={GDGD}>
      <HelpfulLinks />
    </LogoFooter>
  </div>
)
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const Divider = () => (
  <span
    aria-hidden="true"
    role="presentation"
    style={{ speak: 'none' }}
    tabIndex="-1"
  >
    {' | '}
  </span>
)
