import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import './style_cookie-banner.css'
import CookieWrap from './script_cookie-banner'

export default () => (
  <Color>
    <div className="ua_cookie-banner__container" id="ua_cookie-banner__container" hidden>
        <div className="ua_cookie-banner__content">
            <p>This website uses cookies to collect information to improve your browsing experience. Please review our <a href="https://www.ua.edu/privacy">Privacy Statement</a> for more information.</p>
            <button>I understand</button>
        </div>
    </div>
    <CookieWrap />
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 1148,
        padding: '0px 1.0875rem 1.45rem',
        paddingTop: 0,
      }}
    >
      <h2>Helpful Links</h2>
      <Triad>
        <Unit name="CHIME IN" link="https://oira.ua.edu/chime-in/" />
        <Unit
          name="Net Price Calculator"
          link="https://ua.aidcalc.cloud/netprice.htm"
        />
        {/* <Unit
          name="Enrollment at a Glance"
          link="/new/reports/?Report%20Type[0][0]=Enrollment%20at%20a%20Glance"
        /> */}
        {/* <Unit name="OIRA HEAct Information" link="http://oira.ua.edu/HEA/" /> */}
      </Triad>
      <Triad>
        {/* <Unit name="Simple Syllabus (current syllabus system)" link="https://ua.simplesyllabus.com/en-US/syllabus-library" /> */}
        {/* <Unit name="Syllabus Archive (pre-Summer 2023)" link="http://syllabi.ua.edu" /> */}
        <Unit
          name="Accreditation"
          link="https://oie.ua.edu/accreditation"
        />
        {/* <Unit
          name="UA Strategic Measures"
          link="https://oira.ua.edu/strategic-measures-2022/"
        /> */}
      </Triad>
      <Triad>
        {/*<LinkUnit name="PARC Materials" link="parc" />*/}
        <Unit
          name="Organizational Chart"
          link="https://www.ua.edu/about/organizational-chart/"
        />
        <LinkUnit name="External Resources" link="/other-resources/" />
      </Triad>
    </div>
  </Color>
)

const Unit = props => (
  <div>
    <a href={props.link} target="_blank" rel="noopener noreferrer">
      {props.name}
    </a>
  </div>
)

const LinkUnit = props => (
  <div>
    <Link to={props.link} target="_blank" rel="noopener noreferrer">
      {props.name}
    </Link>
  </div>
)

const Triad = styled.div`
  width: 33%;
  display: inline-block;
  line-height: 2.25rem;
  text-align: center;
  @media (max-width: 777px) {
    width: 100%;
    text-align: center;
  }
  @media (max-width: 500px) {
    width: 100%;
    text-align: center;
  }
`
const Color = styled.div`
  h2 {
    color: white;
    text-align: center;
  }
  padding-top: 2em;
  background: linear-gradient(to top, #990000, #990000);

  a {
    color: white;
    &:hover {
      text-decoration: underline;
      font-weight: bold;
    }
  }
  @media print {
    display: none;
  }
`
